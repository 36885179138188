import { useState } from 'react';
import { useSignup } from '../hooks/useSignup';


const Signup = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { signup, isLoading, error } = useSignup();


    const handleSubmit = async (e) => {
        e.preventDefault();

        await signup(email,password);
    }

    return(
        <form className="signupform" onSubmit={ handleSubmit }>
            <label>Email:</label>
            <input type="email" required onChange={(e) => {setEmail(e.target.value)}} value={email} />

            <label>Password:</label>
            <input type="password" required onChange={(e) => {setPassword(e.target.value)}} value={password} />

            <input type="submit" value="Sign Up" disabled={isLoading} />
            {error && <div className='error'>{error}</div>}
        </form>
    )
}

export default Signup;