import { useState } from 'react';
import { useLogin } from '../hooks/useLogin';

const Login = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { login, isLoading, error } = useLogin();


    const handleSubmit = async (e) => {
        e.preventDefault();

        await login(email,password);
    }

    return(
        <form className="loginform" onSubmit={ handleSubmit }>
            <label>Email:</label>
            <input type="email" required onChange={(e) => {setEmail(e.target.value)}} value={email} />

            <label>Password:</label>
            <input type="password" required onChange={(e) => {setPassword(e.target.value)}} value={password} />

            <input type="submit" value="Log In" disabled={isLoading} />
            {error && <div className='error'>{error}</div>}
        </form>
    )
}

export default Login;