import { createContext,useReducer } from 'react';

export const ProjectContext = createContext();

export const projectReducer = (state, action) => {
    switch (action.type){
        case 'SET_PROJECTS':
            return {
                projects: action.payload
            }
        case 'CREATE_PROJECT':
            return {
                projects: [action.payload, ...state.projects]
            }
        case 'DELETE_PROJECT':
            return {
                projects: state.projects.filter((project) =>{
                    if(project._id !== action.payload){ return project}
                })
            }
        case 'UPDATE_PROJECT':
            return {
                projects: state.projects.filter((project) =>{
                    if(project._id !== action.payload){ 
                        return project
                    }else{
                        return action.payload;
                    }
                })
            }
        default:
            return state
    }
}

export const ProjectContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(projectReducer, {
        projects: null
    })

    return(
        <ProjectContext.Provider value={{...state, dispatch}}>
            { children }
        </ProjectContext.Provider>
    )
}
