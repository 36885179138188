const ContactMe = () => {

    return(
        <div id="contactmesection" className='sections'>
            <h1 className="sectiontitle">
            <div class="titleword"></div>
                        <div class="titleword"><span>C</span><span>o</span><span>n</span><span>t</span><span>a</span><span>c</span><span>t</span></div>
                        <div class="titleword"><span>m</span><span>e</span></div>
                        <div class="titleword"><span>/</span></div>
                        <div class="titleword"><span>L</span><span>e</span><span>a</span><span>r</span><span>n</span></div>    
                        <div class="titleword"><span>m</span><span>o</span><span>r</span><span>e</span></div>
            </h1>
            
            <div>
                <div className="contactimg">
                    <img loading="lazy" alt="" src={require(`../ressources/img/contact.jpg`)} />
                </div>
                
                <div className="contacttxt">
                    <div>
                        Email:<a href="mailto:nathanielbloch86@gmail.com">nathanielbloch86@gmail.com</a>
                    </div>
                    <div><a href="https://www.linkedin.com/in/nathanielbloch/" target="_blank">Linkedin</a></div>
                    <div><a href="https://github.com/natbloch" target="_blank">Github</a></div>
                    <div><a href={require(`../ressources/files/Nathaniel_BLOCH_CV.pdf`)} target="_blank">Resume</a></div>
                </div>
            </div>
            
        </div>        
    )
}

export default ContactMe;