import { useState, useEffect } from 'react';
import { useProjectContext } from '../hooks/useProjectContext';
import { useAuthContext } from '../hooks/useAuthContext';


//components
import EachProject from '../components/EachProject';

const MyProjects = () => {
    
    const {projects, dispatch} = useProjectContext();
    const { user } = useAuthContext();
    
    const[modalShow, setModalShow] = useState('hide');
    const[modalContent, setModalContent] = useState("");
    const[maxCount, setMaxCount] = useState(0);
    const[picCount, setpicCount] = useState(0);


    useEffect(() =>{
        let userToken = "";
        if(user){ 
            userToken = user.token;
        }
        const fetchProjects = async () => {
            const response = await fetch('/api/projects', {
                headers:{
                    'Authorization': `Bearer ${userToken}`
                }
            });
            const json = await response.json();
            //console.log(json)
            if(response.ok){
                dispatch({type: "SET_PROJECTS", payload: json});
            }
           
        }

        //if(user){            
            fetchProjects();
        //}


        //Bounce animation on title letters
        const letters = document.querySelectorAll(".sectiontitle span");   
        for (let i = 0; i < letters.length; i++) {
            letters[i].addEventListener("mouseenter", function(e) {
                e.target.classList.add("bounce");                 
            },true);
            letters[i].addEventListener("animationend", (e) => {
                e.target.classList.remove("bounce");
            });
        }
            
        
      

    },[dispatch, user])

    const showModal = (id) => {
        console.log(id);
        projects.forEach((project) => {
            if(project._id != id){
                return;
            }else{
                setModalContent(project);
                setMaxCount(project.picture.length - 1);
                return;
            }                                  
        });
        
        setModalShow("show");
    }

    const closeModal = () => {
        setModalShow("hide");
        setModalContent("");
        setMaxCount(0);
        setpicCount(0);
    }
        
            const previous = () => {
                let newPicCount = 0;
                
                if(picCount == 0){
                    newPicCount = maxCount;
                    setpicCount(newPicCount);
                }else{
                    newPicCount = picCount-1;
                    setpicCount(newPicCount);
                }
                document.querySelector(".modalpic"+picCount).classList.add('hide');
                document.querySelector(".modalpic"+newPicCount).classList.remove('hide');
            }
        
            const next = () => {
                let newPicCount = 0;
                
                if(picCount == maxCount){
                    newPicCount = 0;
                    setpicCount(0);
                }else{
                    newPicCount = picCount+1;
                    setpicCount(newPicCount);
                }
                document.querySelector(".modalpic"+picCount).classList.add('hide');
                document.querySelector(".modalpic"+newPicCount).classList.remove('hide'); 
            }
        
        
    

    return(
        <div id="myprojectssection" className='sections'>
            <div className="projectsectioncontent">
                    <h1 className="sectiontitle">
                        <div class="titleword"><span>S</span><span>o</span><span>m</span><span>e</span></div>
                        <div class="titleword"><span>o</span><span>f</span></div>
                        <div class="titleword"><span>m</span><span>y</span></div>
                        <div class="titleword"><span>p</span><span>r</span><span>o</span><span>j</span><span>e</span><span>c</span><span>t</span><span>s</span></div>   
                    </h1>
                    <div className="projectsctr">
                           {projects ?
                                projects.map((project) => (                                    
                                    <EachProject key={project._id} project={project} showModal={showModal}/>
                                    )) 
                                : "Sorry, Could not retrieve the projects data"}       
                                               
                    </div>
            </div>

            <div className={"modalbck " + modalShow}></div>            
            <div className={"projectmodal " + modalShow}>
                <div className="closebtn" onClick={closeModal}>x</div>
                { modalContent ? 
                <div className="modalcontent">                    
                    <div className='modalgalbox'>
                        {modalContent.picture.length > 1 ? 
                            <div className='previousarr'><span onClick={previous}>{"<"}</span></div>                        
                        : "" }

                        { (Array.isArray(modalContent.picture) && modalContent.picture.length > 0) ? 
                                (modalContent.picture.length == 1) ?
                                <div className='modalimgbox'>
                                    <img loading="lazy" alt="" src={require(`../../public/img/${modalContent.picture[0]}`)} />
                                </div>
                                :<div className='modalimgbox'>
                                    {modalContent.picture.map((picture, index) => {
                                        if(index==0){
                                            return <img loading="lazy" alt="" className={"modalpic modalpic"+index}  src={require(`../../public/img/${picture}`)} />
                                        }else{
                                            return <img loading="lazy" alt="" className={"hide modalpic modalpic"+index}  src={require(`../../public/img/${picture}`)} /> 
                                        }                                        
                                    })}
                                </div>
                        : "" }
                        
                        {modalContent.picture.length > 1 ? 
                            <div className='nextarr'><span onClick={next}>{">"}</span></div>                        
                        : "" }

                    </div>  
                    <h2>{modalContent.title}</h2>
                    <span>
                        {modalContent.languages.map((language, index) => {
                            if (index + 1 === modalContent.languages.length) {
                                return language;
                            }else{
                                return language + ", ";
                            }
                        })}
                    </span>
                    {(modalContent.linkToProject && modalContent.linkToProject.length > 4) ?
                        <a href={modalContent.linkToProject} rel="noreferrer" target="_blank">Link to the website</a>
                    : ""}
                    <p dangerouslySetInnerHTML={{__html:modalContent.description}}></p>
                </div>
                : ""}
            </div>
        </div>        
    )
}

export default MyProjects;