import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useAuthContext } from './hooks/useAuthContext';

//Pages/components
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';


function App() {
  const { user } = useAuthContext();

  return (  
    <div className="App">
      <BrowserRouter>
        
        <div className="pages">
          
          <Routes>
            <Route 
              path='/'
              element={<Home />}
            />
            <Route 
              path='/login'
              element={!user ? <Login /> : <Navigate to="/" />}
            />
            <Route 
              path='/signup'
              element={user ? <Signup /> : <Navigate to="/" />}
            />
            <Route 
              path="*" 
              element={<Navigate to="/" replace />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
