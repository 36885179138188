const AboutMe = () => {

    return(
        <div id="aboutmesection" className='sections'>
            
                <div>
                    <h1 className="sectiontitle">
                        <div class="titleword"><span>H</span><span>i</span><span>,</span></div>
                        <div class="titleword"><span>L</span><span>e</span><span>t</span></div>
                        <div class="titleword"><span>m</span><span>e</span></div>
                        <div class="titleword"><span>i</span><span>n</span><span>t</span><span>r</span><span>o</span><span>d</span><span>u</span><span>c</span><span>e</span></div>
                        <div class="titleword"><span>m</span><span>y</span><span>s</span><span>e</span><span>l</span><span>f</span><span>.</span></div>
                    </h1>
                </div>

                <div>
                    <h2 className="fswd">
                        <span>Full-Stack</span><br />
                        <span>Web Developer.</span>
                    </h2>
                </div>
                
                <div>
                    <h2>Nathaniel<br />
                    Based in Herzliya<br />
                    Programming since 2018.</h2>
                </div>
                
                <div className="textcontent">            
                    <p>
                        I enjoy creating and developing both on the client side and on the server side.<br />
                        I am keen on building aesthetic, intuitive and responsive designs and environments for the user, 
                        as well as manipulating data and solving issues by breaking them down into logic, and in turn translating it into code.
                    </p>
                    
                    <div className="txtcontentparts">
                        <h3>The way I code:</h3>
                        <p>
                            I strive on producing clean, elegant and efficient code, meaning that it will both solve a complex task in the fastest 
                            and the most (relatively) simple manner, as well as being able to be easily read and understood by fellow team members.
                        </p>
                    </div>

                    <div className="txtcontentparts">
                        <h3>The way I work:</h3>
                        <p>
                            I take deadlines very seriously and do all I can to abide by them, without compromising on the quality. 
                            I also consider communication to be essential, whether to give updates and information about the advancement of a mission or tasks, 
                                or if a problem might appear, or again if I consider something could be done better.
                        </p>
                    </div>

                    <div className="txtcontentparts">
                        <h3>Where I learned:</h3>
                        <p>
                            Full-Stack Developer Certificate from the John Bryce Institute of Tel Aviv.<br />
                            Lots of online tutorials and practice (getting some headaches and grey hair while at it).
                        </p>
                    </div>

                    <div className="txtcontentparts">
                        <h3>What I am looking for (my next job):</h3>
                        <p>
                            I am actively searching for my dream job. The one where I will take part in a team where I will be both able to bring the best of myself, 
                            my hard work, my knowledge and my skills and also where I will learn, develop my career, grow and gain more responsibilities.<br />
                            I am looking to join a medium or large high tech company, using advanced technologies and tools, and with a fun, interesting and dynamic 
                            work environment.<br />
                            I am searching preferably in Herzliya Pituah and Poleg zones, but also in other areas towards Netanya, Raanana / Kfar Saba and north Tel aviv.
                        </p>
                    </div>
                </div>
                    
                <div className="stack">
                    <h3>My Stack:</h3>
                    <div>
                        <div className="progbox">
                            <h4>Front-end</h4>
                            <ul className="">
                                <li>HTML</li>
                                <li>CSS (Flexbox, Grid, Sass)</li>
                                <li>Bootstrap (but I prefer clean CSS a lot more)</li>
                                <li>JavaScript</li>
                                <li>jQuery</li>
                                <li>ReactJS</li>
                                <li>AngularJS (the 1st generation)</li>
                            </ul>
                        </div>
                        
                        <div className="progbox">
                            <h4>Back-end</h4>
                            <ul className="">                                
                                <li>Node JS (and frameworks & packages)</li>
                                <li>PHP</li>
                                <li>MongoDB</li>
                                <li>MySQL</li>
                            </ul>
                        </div>
                    </div>
                        
                    <div className="progbox">
                        <h4>Next on my learning list</h4> 
                        <ul className="">                            
                            <li>AWS Practicioner</li>
                            <li>Python</li>
                        </ul>
                    </div>                  
                </div>
              
        </div>        
    )
}

export default AboutMe;
