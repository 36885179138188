import { useAuthContext } from "./useAuthContext";
import { useProjectContext } from "./useProjectContext";

export const useLogout = () => {
    const { dispatch: authDispatch } = useAuthContext();
    const { dispatch: projectDispatch } = useProjectContext();
    //dispatch: xxx   --> means use xxx as name for this dispatch
    
    const logout = () => {
        //remove user/token from loacal storage
        localStorage.removeItem('user');

        //context logaout action
        authDispatch({type: 'LOGOUT_USER'});
        projectDispatch({type: 'SET_PROJECTS', payload: null});
    }

    return { logout };
}