import { useEffect } from "react";
import * as Scroll from 'react-scroll';

//Scroll Vars
let Link = Scroll.Link;


const Panel = () => {
    return(
        <div>
            <div className="panelctr">
                    
                    <Link 
                        activeClass="active"
                        to="aboutmesection"
                        spy={true}
                        smooth={true}
                        hashSpy={true}
                        offset={0}
                        duration={200}
                        delay={100}
                        isDynamic={true}
                        ignoreCancelEvents={false}
                        spyThrottle={100}
                        className="aboutmeLink"
                    >
                        <div><p><strong>WHO</strong> I AM</p></div>
                    </Link>
                    <Link 
                        activeClass="active"
                        to="myprojectssection"
                        spy={true}
                        smooth={true}
                        hashSpy={true}
                        offset={0}
                        duration={200}
                        delay={100}
                        isDynamic={true}
                        ignoreCancelEvents={false}
                        spyThrottle={100}
                        className="myprojectsLink"
                    >
                        <div><p><strong>WHAT</strong> I HAVE CODED</p></div>
                    </Link>
                    <Link 
                        activeClass="active"
                        to="contactmesection"
                        spy={true}
                        smooth={true}
                        hashSpy={true}
                        offset={0}
                        duration={200}
                        delay={100}
                        isDynamic={true}
                        ignoreCancelEvents={false}
                        spyThrottle={100}
                        className="contactmeLink"
                    >
                         <div><p><strong>HOW</strong> YOU CAN REACH ME</p></div>
                    </Link>
                    <Link 
                        activeClass="active"
                        to="personalsection"
                        spy={true}
                        smooth={true}
                        hashSpy={true}
                        offset={0}
                        duration={200}
                        delay={100}
                        isDynamic={true}
                        ignoreCancelEvents={false}
                        spyThrottle={100}
                        className="persoLink"
                    >
                        <div><p><strong>WHERE</strong> YOU CAN FIND ME <br /><span>(when not coding*)</span></p></div>
                    </Link>                                     
            </div>
        </div>       
    )
}

export default Panel;