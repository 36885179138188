import { useAuthContext } from "./useAuthContext";
import { useState } from 'react';

export const useLogin = () => {
    const [error,setError] = useState(null);
    const [isLoading,setIsLoading] = useState(null);
    const {dispatch} = useAuthContext();

    const login = async (email,password) => {
        setIsLoading(true);
        setError(null);

        const response = await fetch('/api/users/login', {
            method: "POST",
            body: JSON.stringify({email,password}),
            headers: {
                "Content-Type": "application/json"
            }        
        })

        const loginUser = await response.json();

        if(!response.ok){            
            setError(loginUser.error);
        }
        if(response.ok){
            //Save the user to the loacl storage so he still has the JWT available when he closes the browser and comes back
            localStorage.setItem('user', JSON.stringify(loginUser));

            //update auth context
            dispatch({type: 'LOGIN_USER', payload: loginUser});

            setIsLoading(false);
        } 
    }

    return { login, isLoading, error };
}