import { useState } from 'react';
import { useProjectContext } from '../hooks/useProjectContext';
import { useAuthContext } from '../hooks/useAuthContext';

const ProjectForm = () => {
    const {projects, dispatch} = useProjectContext();
    const { user } = useAuthContext();

    const [title, setTitle] = useState('');    
    const [description, setDescription] = useState('');
    const [languages, setLanguages] = useState('');    
    const [picture, setPicture] = useState('');
    const [error,setError] = useState(null);
    const [emptyFields,setEmptyFields] = useState([]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(!user){
            setError("You must log in first!");
            return;
        }

        const project = {title, description, languages, picture};
        const response = await fetch('/api/projects', {
            method: "POST",
            body: JSON.stringify(project),
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${user.token}`
            }        
        })

        const json = await response.json();

        if(!response.ok){
            setError(json.error);
            setEmptyFields(json.emptyFields);
        }

        if(response.ok){
            dispatch({type: "CREATE_PROJECT", payload: json});

           //Reset
            setTitle('');
            setDescription('');
            setLanguages('');
            setPicture('');
        }        
    }




    return(
        <form className='projectForm' onSubmit={handleSubmit}>
            <h3>Add a new project</h3>
            
            <label>Project Title</label>
            <input
                type="text"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                className={emptyFields.includes('title') ? 'error' : ''}                
            />

            <label>Project Description</label>
            <input
                type="textarea"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                className={emptyFields.includes('description') ? 'error' : ''}                
            />

            <label>Project Languages</label>
            <input
                type="text"
                onChange={(e) => setLanguages(e.target.value)}
                value={languages}                
            />

            <label>Project Picture</label>
            <input
                type="text"
                onChange={(e) => setPicture(e.target.value)}
                value={picture}                
            />

            <input type="submit" value="Create Project" />

            {error && <div className='error'>{error}</div>}
        </form>
    )
}

export default ProjectForm;

