const Personal = () => {

    return(
        <div id="personalsection" className='sections'>
            <h1 className="sectiontitle">
                        <div class="titleword"><span>B</span><span>e</span><span>y</span><span>o</span><span>n</span><span>d</span></div>
                        <div class="titleword"><span>p</span><span>r</span><span>o</span><span>g</span><span>r</span><span>a</span><span>m</span><span>m</span><span>i</span><span>n</span><span>g</span></div> 
            </h1>
            <div>
                <div className="pic pic1"><img loading="lazy" alt="" src={require(`../ressources/img/perso1.jpg`)} /></div>
                <div className="pic pic2"><img loading="lazy" alt="" src={require(`../ressources/img/perso2.jpg`)} /></div>
                <div className="pic pic3"><img loading="lazy" alt="" src={require(`../ressources/img/perso3.jpg`)} /></div>
                <p className="txt txt1">Being curious and thirsty of learning by nature, I am interested by a lot of topics, especially scientific ones such as astronomy or gemology.</p>
                <p className="txt txt2">I also love travelling and exploring, having travelled in 25+ countries and having lived (worked or studied) in France, Canada, the USA, India and Israel.</p>
                <p className="txt txt3">During my teenagehood, I have raced in skiing up to the european championship level.<br />I also maintain a healthy lifestyle and train, run and practice latin dances.</p>
            </div>                
        </div>        
    )
}

export default Personal;