import { useAuthContext } from "./useAuthContext";
import { useState } from 'react';

export const useSignup = () => {
    const [error,setError] = useState(null);
    const [isLoading,setIsLoading] = useState(null);
    const {dispatch} = useAuthContext();

    const signup = async (email,password) => {
        setIsLoading(true);
        setError(null);

        const response = await fetch('/api/users/signup', {
            method: "POST",
            body: JSON.stringify({email,password}),
            headers: {
                "Content-Type": "application/json"
            }        
        })

        const signUser = await response.json();

        if(!response.ok){            
            setError(signUser.error);
        }
        if(response.ok){
            //Save the user to the loacl storage so he still has the JWT available when he closes the browser and comes back
            localStorage.setItem('user', JSON.stringify(signUser));

            //update auth context
            dispatch({type: 'SIGNUP_USER', payload: signUser});

            setIsLoading(false);
        } 
    }

    return { signup, isLoading, error };
}