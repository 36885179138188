import { useEffect } from "react";
import * as Scroll from 'react-scroll';
import { useAuthContext } from "../hooks/useAuthContext";
import { useLogout } from "../hooks/useLogout";

//Scroll Vars
let Link = Scroll.Link;


const Navbar = () => {
    const { logout } = useLogout();

    const { user } = useAuthContext();

    const logoutClick = () => {
        logout();
    }

    
    
    return(
        <div className="navctr">            
            <nav>
                <ul className="navBox">
                    
                    <Link 
                        activeClass="active"
                        to="aboutmesection"
                        spy={true}
                        smooth={true}
                        hashSpy={true}
                        offset={0}
                        duration={200}
                        delay={100}
                        isDynamic={true}
                        ignoreCancelEvents={false}
                        spyThrottle={100}
                    >
                        <li>About Me</li>
                    </Link>
                    <Link 
                        activeClass="active"
                        to="myprojectssection"
                        spy={true}
                        smooth={true}
                        hashSpy={true}
                        offset={0}
                        duration={200}
                        delay={100}
                        isDynamic={true}
                        ignoreCancelEvents={false}
                        spyThrottle={100}
                    >
                        <li>My Projects</li>
                    </Link>
                    <Link 
                        activeClass="active"
                        to="personalsection"
                        spy={true}
                        smooth={true}
                        hashSpy={true}
                        offset={0}
                        duration={200}
                        delay={100}
                        isDynamic={true}
                        ignoreCancelEvents={false}
                        spyThrottle={100}
                    >
                        <li>Personal</li>
                    </Link>
                    <Link 
                        activeClass="active"
                        to="contactmesection"
                        spy={true}
                        smooth={true}
                        hashSpy={true}
                        offset={0}
                        duration={200}
                        delay={100}
                        isDynamic={true}
                        ignoreCancelEvents={false}
                        spyThrottle={100}
                    >
                         <li>Contact Me</li>
                    </Link>         

                  
                </ul>
                <ul className="userBox">
                    {!user && (
                        <li>
                            <a href='/login'>Log In</a>
                        </li>
                    )}
                    {user && (
                        <li>
                            <span>{user.email} logged</span>
                            <button onClick={logoutClick}>Log Out</button>
                        </li>
                    )}
                </ul>                
            </nav>            
        </div>        
    )
}

export default Navbar;