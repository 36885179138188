
import { useAuthContext } from '../hooks/useAuthContext';


//components
import ProjectForm from '../components/ProjectForm';

//Pages/components

import Navbar from '../components/Navbar';
import Panel from '../components/Panel';
import AboutMe from './AboutMe';
import MyProjects from './MyProjects';
import Personal from './Personal';
import ContactMe from './ContactMe';

const Home = () => {
    const { user } = useAuthContext();

    return(
        <div className="Home">
            <Panel />
            <Navbar />
            <AboutMe />
            <MyProjects />
            <Personal />
            <ContactMe />

            
            {user ?
                    <ProjectForm />
                    : ""}
                    
        </div>
        
    )
}

export default Home;