import { useProjectContext } from "../hooks/useProjectContext";
import { useAuthContext } from "../hooks/useAuthContext";


const EachProject = ({ project, showModal }) => {
    const {projects, dispatch} = useProjectContext();
    const { user } = useAuthContext();

    const deleteProject = async () => {
        if(!user){
            return;
        }
        const response = await fetch('/api/projects/' + project._id, {
            method: "DELETE",
            headers:{
                'Authorization': `Bearer ${user.token}`
            }    
        })

        const json = await response.json();

        if(!response.ok){
            //setError(json.error);
        }

        if(response.ok){
            dispatch({type: "DELETE_PROJECT", payload: project._id});
        }        
    }
    

    return(
        <div className='eachProjectBox' key={project._id} onClick={event => showModal(project._id)}>
                {user ?
                    <button onClick={deleteProject} project-id={project._id}>DELETE</button>
                    : ""            
                }
            <div>
                <img loading="lazy" alt="" src={require(`../../public/img/${project.picture[0]}`)} />
            </div>
            <h2>{project.title}</h2>
            <p dangerouslySetInnerHTML={{__html:project.description}}></p>                                
        </div>
       
    )
}

export default EachProject;

